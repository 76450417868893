export const drawerClosedWidth = 56;
export const drawerWidth = 257;
export const pageSize = 100;
export const domain1 = 'https://kinsalecapital.api.miura.ie/api/v1';
export const domain2 = 'https://kinsalecapitalaif.api.miura.ie/api/v1';
export const domain3 = 'https://kinsalecapitalmifid.api.miura.ie/api/v1';
export const domains = {
  domain1 : 'https://kinsalecapital.api.miura.ie/api/v1',
  domain2 : 'https://kinsalecapitalaif.api.miura.ie/api/v1',
  domain3 : 'https://kinsalecapitalmifid.api.miura.ie/api/v1',
};
const dashboardLinks = {
  domain1: {
    '10 Training': '/business-process?business=2&business_process=96&business_function=22',
    '11 Compliance Reporting': '/business-process?business=2&business_process=57&business_function=16',
    '12 Regulatory Reporting': '/business-process?business=2&business_process=61&business_function=16',
    '13 Audit': '/business-process?business=2&business_process=89&business_function=19',
    '14 Communication with the Regulator': 'business-process?business=2&business_process=52&business_function=16'
  },
  domain2: {
    '10 Training': '/business-process?business=2&business_process=96&business_function=22',
    '11 Compliance Reporting': '/business-process?business=2&business_process=57&business_function=16',
    '12 Regulatory Reporting': '/business-process?business=2&business_process=61&business_function=16',
    '13 Audit': '/business-process?business=2&business_process=89&business_function=19',
    '14 Communication with the Regulator': 'business-process?business=2&business_process=52&business_function=16'
  },
  domain3: {
    '10 Training': '/business-process?business=2&business_process=96&business_function=22',
    '11 Compliance Reporting': '/business-process?business=2&business_process=57&business_function=16',
    '12 Regulatory Reporting': '/business-process?business=2&business_process=61&business_function=16',
    '13 Audit': '/business-process?business=2&business_process=89&business_function=19',
    '14 Communication with the Regulator': 'business-process?business=2&business_process=52&business_function=16'
  }
};

export { dashboardLinks };
export const multiDomain = true;
