import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useCallback } from 'react';

import DvrIcon from '@material-ui/icons/Dvr';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import ReplyIcon from '@material-ui/icons/Reply';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import CreateIcon from '@material-ui/icons/Create';
import PublicIcon from '@material-ui/icons/Public';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AlarmIcon from '@material-ui/icons/Alarm';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CommentIcon from '@material-ui/icons/Comment';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useStore } from 'hooks';
import { dashboardLinks } from  "../../../config/constants";


const frameworkMenuItems = [
    {
    label: 'Dashboard',
    icon: <DvrIcon style={{ color: '#fff' }} />,
    path: '/dashboard',
  },
    {
    label: '01 Horizon Scanning',
    icon: <PublicIcon style={{ color: '#fff' }} />,
    path: '/horizon-scanning',
  },
  {
    label: '02 Impact Assessment',
    icon: <AssignmentReturnIcon style={{ color: '#fff' }} />,
    path: '/impact-assessment',
  },
  {
    label: '03 Compliance Document Creation',
    icon: <CreateIcon style={{ color: '#fff' }} />,
    path: '/businesses',
  },
  {
    label: '04 Regulation Management Dashboard',
    icon: <LowPriorityIcon style={{ color: '#fff' }} />,
    path: '/reg-change-management',
  },
  {
    label: '05 Compliance Task/Issue Management Dashboard',
    icon: <ReportProblemIcon style={{ color: '#fff' }} />,
    path: '/issue-management',
  },
  {
    label: '06 Compliance Monitoring Programme',
    icon: <ListAltIcon style={{ color: '#fff' }} />,
    path: '/user-micro-regulatory-list',
  },
  {
    label: '07 Real-time Ops Controls Framework',
    icon: <ViewListIcon style={{ color: '#fff' }} />,
    path: '/regulation-documents-operational',
  },
  {
    label: '08 Real-time Reg Compliance Framework',
    icon: <FormatListNumberedIcon style={{ color: '#fff' }} />,
    path: '/regulation-documents-regulation',
  },
  {
    label: '09 Advising the Business',
    icon: <SpeakerNotesIcon style={{ color: '#fff' }} />,
    path: '/businesses',
  },
  {
    label: '10 Training',
    icon: <PeopleAltIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: '11 Compliance Reporting',
    icon: <AssessmentIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: '12 Regulatory Reporting',
    icon: <AlarmIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: '13 Audit',
    icon: <FindInPageIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: '14 Communication with the Regulator',
    icon: <CommentIcon style={{ color: '#fff' }} />,
    path: '',
  },
  {
    label: '15 Micro Process Library',
    icon: <LibraryBooksIcon style={{ color: '#fff' }} />,
    path: '/evident-micro-regulatory-list',
  },

];

function Menu() {
  const history = useHistory();
  const store = useStore();
  const classes = useStyles();
  const [frameworkSubNavOpen, setFrameworkSubNavOpen] = React.useState(true);

  const redirectTo = useCallback(
    (path,label, menu) => () => {
      const domain = localStorage.getItem('domain');
      const menuPath = path ? path : menu[domain][label];
      history.push(menuPath);

    },
    [],
  );

  const handleLogout = useCallback(() => {
    store.user.logout();
  }, []);

  const handleFrameworkSubNavClick = () => {
    // setFrameworkSubNavOpen(!frameworkSubNavOpen);
    setFrameworkSubNavOpen(true);
  };

  return (
    <>
      <List component="nav">
        <ListItem button onClick={redirectTo('/companies-dashboard')}>
          <ListItemIcon>
            <HomeIcon style={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Control Centre" />
        </ListItem>
      </List>

      <Divider className={classes.divider} />

      <List component="nav">
        <Collapse in={frameworkSubNavOpen} timeout="auto" unmountOnExit>
          <List>
            {frameworkMenuItems.map((item) => (
              <ListItem key={item.label} button onClick={redirectTo(item.path, item.label, dashboardLinks)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ReplyIcon style={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: '#ffffff',
  },
}));

export default Menu;
