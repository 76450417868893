import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import { useHistory } from 'react-router-dom';
import {useStore} from "../../hooks";
import {useSnackbar} from "notistack";
import * as config from "../../config/constants";

const IssueManagementDashboard = () => {
  const history = useHistory();
  const [microRegulatoryProcessNext, setMicroRegulatoryProcessNext] = useState('');
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [microRegulatoryProcessCount, setMicroRegulatoryProcessCount] = useState(0);
  const [microRegulatoryProcessDisplayCount, setMicroRegulatoryProcessDisplayCount] = useState(0);
  const [microRegulatoryProcessLoading, setMicroRegulatoryProcessLoading] = useState(false);
  const store = useStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {}, []);
  const numRecordPage = config.pageSize;

  const getMicroRegulatoryProcessList = async () => {
    try {
      const { data } = await store.api.getMicroProcessesFailed();
      setMicroRegulatoryProcessData(data.results);
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessCount(data.count);
      data.count > numRecordPage
        ? setMicroRegulatoryProcessDisplayCount(numRecordPage)
        : setMicroRegulatoryProcessDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };
 const getNextMicroRegulatoryProcesses = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const { data } = await store.api.getNextDataWithURL(microRegulatoryProcessNext);
      let newMicroRegulatoryProcesses = microRegulatoryProcessData;
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        newMicroRegulatoryProcesses.push(process);
        if (arrayLength == i + 1) {
          setMicroRegulatoryProcessData(newMicroRegulatoryProcesses);
          setMicroRegulatoryProcessDisplayCount(microRegulatoryProcessDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setMicroRegulatoryProcessLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

  useEffect(() => {
    getMicroRegulatoryProcessList();
  }, []);

  const navigateToMicroRegulatoryProcess = (microProcessId) => {
    const path = 'user-micro-regulatory-process-detail?micro_process='+microProcessId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <AnnouncementOutlinedIcon
        style={{
          width: '42px',
          height: '42px',
          color: '#e0123c',
          fontSize: 'large',
          position: 'absolute',
        }}
      />
      <h4>Task/Issue Management Dashboard</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
        <div>
        <div>The following screen lists all the Regulatory Micro-processes that have passed their Review Date and not been actioned or have failed the daily Automated Compliance testing.&nbsp; &nbsp;</div>
        <div>&nbsp;</div>
        <div><strong>ACTION</strong>: Process Owners will be responsible for Reviewing the issues and resolving them.</div>
        <div><strong>RESULT</strong>: Regulatory Micro-processes will be removed from this screen when they have passed validation and return to their Original Compliance Monitoring Programme.</div>
        <div>&nbsp;</div>
        </div>
        <p>&nbsp;</p>
            <span>
            Displaying {microRegulatoryProcessDisplayCount} of {microRegulatoryProcessCount} Micro
            Regulatory Processes
          </span>
        </li>
          {microRegulatoryProcessData !== undefined &&
          microRegulatoryProcessData.map((microProcess, i) => {
            const microProcessId = microProcess.id;

            return (
              <li key={i} onClick={() => navigateToMicroRegulatoryProcess(microProcessId)}>
                <span>{microProcessId}</span>
                <span className="title-spacing">{microProcess.micro_regulatory_process.name}</span>
                <div className="status-text">
                  <span className="owner"> Owner: {microProcess.owner.first_name}  {microProcess.owner.last_name}</span>
                    <span className="last-updated">Last Updated At {microProcess.last_updated}</span>
                </div>
                {microProcess.validation_status == 'Passed' ? (
                  <div className="status green-status"></div>
                ) : (
                  <div className="status red-status"></div>
                )}
              </li>
            );
          })}
        {microRegulatoryProcessCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextMicroRegulatoryProcesses()}>
            <span>{microRegulatoryProcessLoading ? 'Loading...' :  'Load ' + numRecordPage.toString() + ' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};
IssueManagementDashboard.defaultProps = {};

export default IssueManagementDashboard;
